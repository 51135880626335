import React, { useState } from "react"
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import { handleSubmit } from '../utils/formUtils';

const Reserveren = () => {
    const [beursFormData, setBeursFormData] = useState({
        naam: "",
        email: "",
        telefoon: "",
        organisatie: "",
        standplaats: "",
        standnummer: ""
    });

    const [dataSent, setDataSent] = useState(false);

    const handleInputChange = (e) => {
        const { name, value} = e.target;
        setBeursFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    
    return (
        <div className="reserveren__container">
            <Hero heroTitle="Beursplaats reserveren"/>
            <div className="reserveren__header">
            <h2 >Reserveer een standplaats voor 2025</h2>
            <p>Hieronder kunt u een standplaats reserveren voor de volgende editie op 27 maart. Informatie en prijzen van de standplaatsen vindt u <Link to="/prijzen">hier.</Link></p>
            </div>
            
            <div className="reserveren__form">
                <form onSubmit={(e) => handleSubmit(e, beursFormData, "https://test.waterinfodag.nl/api/beursplaats", setBeursFormData, setDataSent)}>
                    <label htmlFor="naam">Naam</label>
                    <input
                        type="text"
                        name="naam"
                        id="naam"
                        value={beursFormData.naam}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="email">E-mailadres</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={beursFormData.email}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="telefoon">Telefoonnummer</label>
                    <input
                        type="tel"
                        name="telefoon"
                        id="telefoon"
                        value={beursFormData.telefoon}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="organisatie">Organisatie</label>
                    <input
                        type="text"
                        name="organisatie"
                        id="organisatie"
                        value={beursFormData.organisatie}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="standplaats">Welke standplaats wenst u?</label>
                        <select name="standplaats" value={beursFormData.standplaats} onChange={handleInputChange} required>
                            <option value="standplaats">Standplaats</option>
                            <option value="bannerstand">Bannerstand</option>
                            <option value="praattafel">Praattafel</option>
                        </select>
                    <label htmlFor="standnummer">Voorkeur standnummer</label>
                    <input
                        type="text"
                        name="standnummer"
                        id="standnummer"
                        value={beursFormData.standnummer}
                        onChange={handleInputChange}
                    />
                    <input type="submit" />
                </form>
                {
                    dataSent && (<p>Bedankt voor de reservering. We nemen zo snel mogelijk contact met u op</p>)
                }
            </div>
        </div>
    )
}

export default Reserveren;