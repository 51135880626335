import React, { useState } from "react"
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import { handleSubmit } from '../utils/formUtils';

const PresentatieVoorstel = () => {
    const [presentation, setPresentation] = useState({
        email: "",
        name: "",
        phone: "",
        organisation: "",
        title: "",
        description: "",
        summary: "",
        topi: "",
        target_audience: "",
        specific_target_audience: "",
        presentation_form: "",
        interaction: "",
        comment: "",
        timeslot: "",
    });

    const [dataSent, setDataSent] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPresentation((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="reserveren__container">
            <Hero heroTitle="Beursplaats reserveren" />
            <div className="reserveren__header">
                <h2>Dien een presentatie voorstel in</h2>
            </div>

            <div className="reserveren__form">
                <form onSubmit={(e) => handleSubmit(e, presentation, "https://test.waterinfodag.nl/api/presentations", setPresentation, setDataSent)}>
                    <label htmlFor="naam">Title</label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        value={presentation.title}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="naam">Naam</label>
                    <input
                        type="text"
                        name="naam"
                        id="naam"
                        value={presentation.naam}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="naam">E-mail</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={presentation.email}
                        onChange={handleInputChange}
                        required
                    />
                    <input type="submit" />
                </form>
                {
                    dataSent && (<p>Bedankt voor de toevoeging. We nemen zo snel mogelijk contact met u op</p>)
                }
            </div>
        </div>
    )
}

export default PresentatieVoorstel;