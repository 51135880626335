import React, { useState } from "react"
import Hero from "../components/Hero";
import { handleSubmit } from '../utils/formUtils';

const Contact = () => {
    const [contactFormData, setContactFormData] = useState({
        email: "",
        voornaam: "",
        message: ""
    });

    const [dataSent, setDataSent] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    
    return (
        <div className="container-contact">
            <Hero heroTitle={"Contact"}/>
            <h2 className="titel">Neem contact met ons op</h2>
            <div className="contact">
                <form onSubmit={(e) => handleSubmit(e, contactFormData, "https://test.waterinfodag.nl/api/contact", setContactFormData, setDataSent)}>
                    <label htmlFor="email">E-mailadres</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={contactFormData.email}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="voornaam">Voornaam</label>
                    <input
                        type="text"
                        name="voornaam"
                        id="voornaam"
                        value={contactFormData.voornaam}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="message">Bericht</label>
                    <textarea
                        name="message"
                        id="message"
                        value={contactFormData.message}
                        onChange={handleInputChange}
                    />
                    <input type="submit" />
                </form>
                {
                    dataSent && (<p>Bedankt voor uw bericht. We nemen zo snel mogelijk contact met u op</p>)
                }
            </div>
        </div>
    )
}

export default Contact;