import React, { useEffect } from "react";
import Foto1 from "../assets/waterinfodag-home-1.webp";
import NieuwsbriefAanmelden from "../components/NieuwsbriefAanmelden";
import Hero from "../components/Hero";

import { Link, useLocation } from "react-router-dom";

const Home = () => {
    const { hash } = useLocation();
    console.log(hash);

    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [hash]);

    return (

        <div className="container-home">
            <Hero heroTitle={"Waterinfodag"} />
            <div className="header_info1">
                <h2>OVER WATERINFODAG</h2>
                <div className="separator"></div>
            </div>

            <p className="info1">De netwerkdag over de informatievoorziening in de watersector.<br /><br />

                De Waterinfodag is een congres, beurs en ontmoetingsdag. Voor medewerkers van waterschappen, Rijkswaterstaat, provincies, gemeenten, drinkwaterbedrijven, bedrijven en studenten. Er is een beurs met 60 exposanten, een actueel, aansprekend en breed congresprogramma en volop gelegenheid om te netwerken. De Waterinfodag wordt sinds 2009 georganiseerd.
                <br /><br />
                De 15e waterinfodag is op donderdag 27 maart 2025. De <Link to="/reserveren">beursinschrijving</Link> is geopend.
                Schrijf u hieronder in voor onze nieuwsbrief en blijf op de hoogte van het laatste nieuws.
            </p>

            <div className="counter">
                <div>
                    <h2>60</h2>
                    <p>exposanten</p>
                </div>
                <div>
                    <h2>800</h2>
                    <p>Deelnemers</p>
                </div>
                <div>
                    <h2>50</h2>
                    <p>Presentaties</p>
                </div>
            </div>
            <div className="image1">
                <img src={Foto1} />
            </div>

            <NieuwsbriefAanmelden />
        </div>
    )
}

export default Home;