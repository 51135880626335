import React, { useState } from 'react';
import LinkedInLogo from "../assets/linkedin-logo.svg";
import WaterinfodagMenuLogo from "../assets/waterinfodag-logo.webp";
import { ReactComponent as Hamburger } from "../assets/icons/hamburger-menu.svg";

import { NavLink } from 'react-router-dom';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [subIsOpen, setSubIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setSubIsOpen(false);
    };

    const toggleSubDropdown = () => {
        setSubIsOpen(!subIsOpen);
    };

    const handleMouseEnter = () => {
        setIsOpen(true);
        console.log("enter")
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };


    return (
        <>
            <div className="menu-1">
                <div className="menu-1-social-logo-container">
                    <a href="https://www.linkedin.com/company/waterinfodag" target="_blank"><img src={LinkedInLogo} className="menu-1-social-logo" /></a>
                </div>
                <p><strong>Mail ons: info@waterinfodag.nl</strong></p>
            </div>
            <div className="menu-2-container">
                <div className="menu-2-logo">
                    <NavLink to=""><img src={WaterinfodagMenuLogo} /></NavLink>
                </div>
                <div className="menu-2">
                    <NavLink to="/beurs" className="menu-2-link" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>Beurs</NavLink>
                    {isOpen && (
                        <div className="menu2__dropdown__beurs" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                            <NavLink to="/beurs" onClick={toggleDropdown}>Plattegrond</NavLink>
                            <NavLink to="/prijzen" onClick={toggleDropdown}>Prijzen</NavLink>
                            <NavLink to="/reserveren" onClick={toggleDropdown}>Reserveren</NavLink>
                        </div>
                    )}
                    <NavLink to="/deelname" className="menu-2-link">Deelname</NavLink>
                    <NavLink to="/contact" className="menu-2-link">Contact</NavLink>
                </div>
                <div className='menu-2-hamburger'>
                    <Hamburger
                        width="100%"
                        height="100%"
                        onClick={toggleDropdown}
                    />
                    {isOpen && (
                        <div className="menu2__dropdown__hamburger">
                            <NavLink to="/beurs" className="menu-2-link" onClick={toggleSubDropdown}>Beurs</NavLink>
                            {subIsOpen && (
                                <div className='menu2__dropdown__hamburger__sub'>
                                    <NavLink to="/beurs" onClick={toggleDropdown}>Plattegrond</NavLink>
                                    <NavLink to="/prijzen" onClick={toggleDropdown}>Prijzen</NavLink>
                                    <NavLink to="/reserveren" onClick={toggleDropdown}>Reserveren</NavLink>
                                </div>
                            )}
                            <NavLink to="/deelname" className="menu-2-link" onClick={toggleDropdown}>Deelname</NavLink>
                            <NavLink to="/contact" className="menu-2-link" onClick={toggleDropdown}>Contact</NavLink>
                        </div>
                    )}
                    
                </div>
            </div>
        </>
    )
}

export default Header;