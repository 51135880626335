import React from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

// import logo from './logo.svg';
// Componenten
import Root from "./components/Root";
import Home from "./pages/Home";
import Beurs from "./pages/Beurs";
import Prijzen from "./pages/Prijzen";
import Reserveren from "./pages/Reserveren";
import Deelname from "./pages/Deelname";
import Contact from "./pages/Contact";
import PresentatieVoorstel from "./pages/PresentatieVoorstel";

// fetch data helper
// import getPresentationData from "./helpers/FetchPresentationData";
// const data = getPresentationData();
// console.log(data);

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<Root />}>
    <Route index element={<Home />} />
    <Route path="beurs" element={<Beurs />} />
    <Route path="deelname" element={<Deelname />} />
    <Route path="contact" element={<Contact />} />
    <Route path="prijzen" element={<Prijzen />} />
    <Route path="reserveren" element={<Reserveren />} />
    <Route path="newsletter" />
    <Route path="PresentatieVoorstel" element={<PresentatieVoorstel />} />
  </Route>
));

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
