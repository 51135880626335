export const handleSubmit = async (e, formData, actionUrl, setFormData, setDataSent) => {
    e.preventDefault();
    try {
        const response = await fetch(actionUrl, {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(formData)
        });

        if (!response.ok) {
            throw new Error("Er is een probleem met het verzenden van je gegevens.");
        }

        // Reset formData na succesvolle verzending
        setFormData(() => {
            const resetData = {};
            Object.keys(formData).forEach(key => {
                resetData[key] = '';  // Leeg elk veld in het formulier
            });
            return resetData;
        }); 
        
        setDataSent(true);
    } catch (error) {
        console.error(error.message);
        // Toon een foutmelding aan de gebruiker
    }
};